// this will be replaced by actual hash post-build.js
let currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
let intervalId = null;

/**
 * checks in every set frequency the version of frontend application
 *
 * @param url the url of the generated version.json file
 * @param frequency - in milliseconds, defaults to 5 minutes
 */
function startVersionCheck(url, frequency = 1000 * 60 * 5) {
  if (intervalId) {
    clearInterval(intervalId); // clear any existing interval
  }
  checkVersion(url); // call the first check immediately
  intervalId = setInterval(() => {
    checkVersion(url);
  }, frequency);
}

/**
 * @param hash the latest hash from the API
 * @returns a flag whether the version changed or not
 */
function versionDidChange(hash) {
  const hashDidChange = didHashChange(currentHash, hash);
  currentHash = hash;
  return hashDidChange;
}
function checkVersion(url) {
  getVersionHash(url)
    .then(({ hash }) => {
      if (versionDidChange(hash)) {
        window.location.reload();
      }
    })
    .catch(error => {
      console.log(error);
    });
}

/**
 * will do the call and check if the hash has changed or not
 *
 * @param url the url of the generated version.json file
 */
function getVersionHash(url) {
  // timestamp these requests to invalidate caches
  const timestampUrl = url + '?t=' + new Date().getTime();
  return fetch(timestampUrl).then(response => response.json());
}

/**
 * Checks if hash has changed.
 * This file has the JS hash, if it is a different one than in the version.json
 * we are dealing with version change
 *
 * @param currentHash the version hash
 * @param newHash the new app version hash
 * @returns a flag whether it changed or not
 */
function didHashChange(currentHash, newHash) {
  if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
    return false;
  }
  return currentHash !== newHash;
}

export default {
  startVersionCheck,
};
