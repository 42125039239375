import moment from 'moment';
import enums from 'enums/index';
import { dangerColor, primaryColor } from 'assets/jss/material-dashboard-pro-react';
const { TerminationReasonCategories, HelperTerminationReasons, ClientTerminationReasons } = enums;

const invoiceTypesFilterObj = {
  [enums.InvoiceTypes.EB]: 'Entlastungsbetrag',
  [enums.InvoiceTypes.VP]: 'Verhinderungspflege',
  [enums.InvoiceTypes.B_EB]: 'Beihilfe Entlastungsbetrag',
  [enums.InvoiceTypes.B_VP]: 'Beihilfe Verhinderungspflege',
  [enums.InvoiceTypes.PRIVATE_EXTRA]: 'Eigenanteilsrechnung',
  [enums.InvoiceTypes.PRIVATE_ALL]: 'Privat',
};
const clientMonthlyStatusFilter = {
  [enums.ClientInvoicesStatuses.UNCONFIRMED]: 'Nicht bestätigt',
  [enums.ClientInvoicesStatuses.CONFIRMED]: 'Bestätigt',
  [enums.ClientInvoicesStatuses.DECLINED]: 'Abgelehnt',
};
const PrivateInvoicesStatusesObj = {
  [enums.PrivateInvoicesStatuses.CREATED]: 'Erstellt',
  [enums.PrivateInvoicesStatuses.SENT]: 'Senden',
  [enums.PrivateInvoicesStatuses.PAID]: 'Bezahlt',
};

export default {
  gendersObj: {
    [enums.Gender.MALE]: 'Herr',
    [enums.Gender.FEMALE]: 'Frau',
  },
  helperGendersObj: {
    [enums.Gender.MALE]: 'Helfer',
    [enums.Gender.FEMALE]: 'Helferin',
  },
  clientGendersObj: {
    [enums.Gender.MALE]: 'Kunde',
    [enums.Gender.FEMALE]: 'Kundin',
  },
  perOptionsObj: {
    [enums.Per.WEEK]: 'pro Woche',
    [enums.Per.TWO_WEEKS]: 'á 14 Tage',
    [enums.Per.MONTH]: 'pro Monat',
    [enums.Per.QUARTER]: 'pro Quartal',
    [enums.Per.HALF_YEAR]: 'pro halbes Jahr',
    [enums.Per.YEAR]: 'pro Jahr',
  },
  salutationsObj: {
    [enums.Salutation.DR]: 'Dr.',
    [enums.Salutation.DR_MED]: 'Dr. med.',
    [enums.Salutation.PROF]: 'Prof.',
    [enums.Salutation.PROF_DR]: 'Prof. Dr.',
    [enums.Salutation.PROF_DR_MED]: 'Prof. Dr. med.',
  },
  RecommendedByObj: {
    [enums.RecommendedBy.NURSING_BASE]: 'Pflegestützpunkt',
    [enums.RecommendedBy.NURSING_SERVICE]: 'Pflegedienst',
    [enums.RecommendedBy.NURSING_NAVIGATOR]: 'Pflegenavigator',
    [enums.RecommendedBy.INTERNET]: 'Internet',
    [enums.RecommendedBy.FRIEND]: 'Bekannte/r',
    [enums.RecommendedBy.MDK]: 'MDK',
    [enums.RecommendedBy.NEWSPAPER]: 'Zeitung',
    [enums.RecommendedBy.FLYER]: 'Flyer',
    [enums.RecommendedBy.HEALTH_INSURANCE_LIST]: 'Liste Krankenkasse',
    [enums.RecommendedBy.CLIENT]: 'Kunde',
    [enums.RecommendedBy.HELPER]: 'Helfer/in',
    [enums.RecommendedBy.INSTAGRAM]: 'Instagram',
    [enums.RecommendedBy.EBAY]: 'Ebay',
    [enums.RecommendedBy.INDEED]: 'Indeed',
    [enums.RecommendedBy.STUDENT_JOB]: 'Schülerjob',
    [enums.RecommendedBy.GOOGLE]: 'Google',
    [enums.RecommendedBy.OTHER]: 'Sonstige',
  },
  helperSituationsObj: {
    [enums.HelperSituation.STUDENT_1]: 'Schüler/in',
    [enums.HelperSituation.STUDENT_2]: 'Student/in',
    [enums.HelperSituation.TRAINEE]: 'Auszubildende/r',
    [enums.HelperSituation.APPLICANT]: 'Studienbewerber/in',
    [enums.HelperSituation.STUDENT_NEXT_SEMESTER]: 'Student/in ab kommenden Semester',
    [enums.HelperSituation.LOOKING_FOR_TRAINING]: 'Ausbildung suchend',
    [enums.HelperSituation.WORKER]: 'Arbeiter/in',
    [enums.HelperSituation.SOCIAL_SERVICE]: 'FSJ',
    [enums.HelperSituation.LOOKING_FOR_JOB]: 'Arbeitssuchend',
    [enums.HelperSituation.OTHER]: 'Sonstige',
  },
  schoolDegreesObj: {
    [enums.SchoolDegree.NO_DEGREE]: 'Ohne Schulabschluss',
    [enums.SchoolDegree.ELEMENTARY]: 'Haupt-/Volksschulabschluss',
    [enums.SchoolDegree.MIDDLE_SCHOOL]: 'mittlere Reife oder gleichw. Abschluss',
    [enums.SchoolDegree.ABITUR]: 'Abitur/Fachabitur',
    [enums.SchoolDegree.UNKNOWN]: 'Abschluss unbekannt',
  },
  workDegreesObj: {
    [enums.WorkDegree.NO_DEGREE]: 'Ohne Berufsausbildung',
    [enums.WorkDegree.RECOGNIZED_PROFESSIONAL_TRAINING]: 'Abschluss einer anerkannten Berufsausbildung',
    [enums.WorkDegree.TECHNICAL_DEGREE]: 'Meister-/Techniker- oder gleichw. Fachschulabschluss',
    [enums.WorkDegree.BACHELOR]: 'Bachelor',
    [enums.WorkDegree.DIPLOMA]: 'Diplom/Magister/Master/Staatsexamen',
    [enums.WorkDegree.PROMOTION]: 'Promotion',
    [enums.WorkDegree.UNKNOWN]: 'Ausbildung unbekannt',
  },
  insuranceTypesObj: {
    [enums.InsuranceType.LEGAL]: 'Gesetzlich',
    [enums.InsuranceType.PRIVATE]: 'Privat',
  },
  healthInsuranceObj: {
    [enums.HealthInsurance.NO_CONTRIBUTION]: 'Kein Beitrag',
    [enums.HealthInsurance.GENERAL_CONTRIBUTION]: 'allgemeiner Beitrag',
    [enums.HealthInsurance.REDUCED_CONTRIBUTION]: 'ermäßigter Beitrag',
    [enums.HealthInsurance.FLAT_RATE]: 'pauschal',
    [enums.HealthInsurance.REFUND_OF_PRIVATE_INSURANCE]: 'Erstattung private Krankenversicherung',
    [enums.HealthInsurance.VOLUNTARILY_INSURED]: 'freiwillig gesetzlich Versicherte',
  },
  pensionInsuranceObj: {
    [enums.PensionInsurance.NO_AMOUNT]: 'Kein Betrag',
    [enums.PensionInsurance.FULL_AMOUNT]: 'voller Betrag',
    [enums.PensionInsurance.HALF_AMOUNT]: 'halber Betrag',
    [enums.PensionInsurance.FLAT_RATE]: 'Pauschalbeitrag gering',
    [enums.PensionInsurance.EMPLOYED]: 'Beschäftigt',
    [enums.PensionInsurance.PENSION_INSURANCE_REFUND]: 'Erstattung Rentenversicherung',
  },
  drivingMethodsObj: {
    [enums.DrivingMethod.FOOT]: 'Zu Fuß',
    [enums.DrivingMethod.LOCAL_PUBLIC_TRANSPORT]: 'Öffentlicher Nahverkehr',
    [enums.DrivingMethod.BICYCLE]: 'mit dem Fahrrad',
    [enums.DrivingMethod.CAR]: 'mit dem Auto',
    [enums.DrivingMethod.MOTORCYCLE]: 'mit dem Motorrad',
    [enums.DrivingMethod.SCOOTER]: 'mit dem Roller',
  },
  invitationsStatusObj: {
    ['-1']: 'Not invited',
    [enums.InvitationStatus.PENDING]: 'Versendet',
    [enums.InvitationStatus.REJECTED]: 'Abgelehnt',
    [enums.InvitationStatus.ACCEPTED]: 'Akzeptiert',
    [enums.InvitationStatus.EXPIRED]: 'Abgelaufen',
    [enums.InvitationStatus.HOLD]: 'Warteschleife',
    [enums.InvitationStatus.MATCHED]: 'Zugeteilt',
    [enums.InvitationStatus.FAILED]: 'Fehlgeschlagen',
    [enums.InvitationStatus.CANCELED]: 'Abgebrochen',
    [enums.InvitationStatus.COMPLETED]: 'Fertiggestellt',
    [enums.InvitationStatus.SCHEDULED]: 'Geplant',
  },
  visitsStatusObj: {
    [enums.VisitStatus.PLANNED]: 'Geplant',
    [enums.VisitStatus.CONFIRMED]: 'Bestätigt',
    [enums.VisitStatus.DECLINED_BY_ADMIN]: 'Abgelehnt durch admin',
  },
  monthlyVisitsStatusObj: {
    [enums.MonthlyVisitStatuses.UNCONFIRMED]: 'Unbestätigt',
    [enums.MonthlyVisitStatuses.CONFIRMED]: 'Bestätigt',
    [enums.MonthlyVisitStatuses.DECLINED]: 'Abgelehnt',
  },
  clientMonthlyStatusFilter: {
    ...clientMonthlyStatusFilter,
  },
  clientMonthlyStatus: {
    [enums.ClientInvoicesStatuses.ALL]: 'Alle',
    ...clientMonthlyStatusFilter,
    [enums.ClientInvoicesStatuses.READY_FOR_INVOICE]: 'Bereit für die Rechnung',
  },

  workLocationsObj: {
    [enums.WorkLocations.BERLIN]: 'Berlin',
    [enums.WorkLocations.BOCHUM]: 'Bochum',
    [enums.WorkLocations.CHEMNITZ]: 'Chemnitz',
    [enums.WorkLocations.DORTMUND]: 'Dortmund',
    [enums.WorkLocations.DRESDEN]: 'Dresden',
    [enums.WorkLocations.DUISBURG]: 'Duisburg',
    [enums.WorkLocations.DÜSSELDORF]: 'Düsseldorf',
    [enums.WorkLocations.ESSEN]: 'Essen',
    [enums.WorkLocations.FRANKFURT]: 'Frankfurt',
    [enums.WorkLocations.KÖLN]: 'Köln',
    [enums.WorkLocations.LEIPZIG]: 'Leipzig',
    [enums.WorkLocations.WIESBADEN]: 'Wiesbaden',
  },
  helperTerminationStatusesObj: {
    [enums.HelperTerminationStatuses.REQUESTED]: 'Angefordert',
    [enums.HelperTerminationStatuses.ACCEPTED]: 'Akzeptiert',
    [enums.HelperTerminationStatuses.DECLINED]: 'Abgelehnt',
    [enums.HelperTerminationStatuses.DONE]: 'Erledigt',
  },
  helperVacationStatusesObj: {
    [enums.HelperVacationStatuses.REQUESTED]: 'Angefordert',
    [enums.HelperVacationStatuses.ACCEPTED]: 'Akzeptiert',
    [enums.HelperVacationStatuses.DECLINED]: 'Abgelehnt',
  },
  noReplacementNeededReasonObj: {
    [enums.NoReplacementNeededReason.CLIENT_NO_NEED]: 'Kunde möchte keinen anderen Helfer',
    [enums.NoReplacementNeededReason.CLIENT_ON_VACATION]: 'Kunde ist ebenfalls im Urlaub',
    [enums.NoReplacementNeededReason.HOURS_COMPLETED]: 'Stundenanzahl wird eingehalten',
    [enums.NoReplacementNeededReason.OTHER]: 'Sonstige',
  },
  jiraStatusesObj: {
    [enums.JiraStatuses.TODO]: 'Machen',
    [enums.JiraStatuses.IN_PROGRESS]: 'im Gange',
    [enums.JiraStatuses.REVIEW]: 'Rezension',
    [enums.JiraStatuses.TESTING]: 'testen',
    [enums.JiraStatuses.DONE]: 'Erledigt',
  },
  invitationDeclineReasonObj: {
    [enums.InvitationDeclineReason.DISTANCE_TIME_TOO_LONG]: 'Entfernung / Fahrtzeit zu lang',
    [enums.InvitationDeclineReason.ORDER_UNCLEAR]: 'Auftragstext unklar / nicht ausreichend',
    [enums.InvitationDeclineReason.EXCESSIVE_AMOUNT]: 'Zu hoher Umfang (Stunden)',
    [enums.InvitationDeclineReason.TOO_LITTLE_SCOPE]: 'Zu geringer Umfang (Stunden)',
    [enums.InvitationDeclineReason.TYPE_OF_SERVICE]: 'Art der Dienstleistung',
    [enums.InvitationDeclineReason.OTHER]: 'Sonstiges',
  },
  trueFalseObj: {
    true: 'WAHR',
    false: 'FALSCH',
  },
  jiraPrioritiesObj: {
    Highest: 'Höchste',
    High: 'Hoch',
    Medium: 'Mittel',
    Low: 'Niedrig',
    Lowest: 'Niedrigste',
  },
  helperDocumentTypesOBJ: {
    'Ausweisdokument (Personalausweis/Reisepass)': enums.HelperDocumentTypes.ID,
    Vertrag: enums.HelperDocumentTypes.CONTRACT,
    Arbeitsbelehrung: enums.HelperDocumentTypes.WORK_INSTRUCTION,
    Aufnahmebogen: enums.HelperDocumentTypes.RECORDING_SHEET,
    '3G Nachweis': enums.HelperDocumentTypes.PROOF_3G,
    Schulungszertifikat: enums.HelperDocumentTypes.TRAINING_CERTIFICATE,
    'Schul-/Immatrikulations-/Ausbildungsbescheinigung': enums.HelperDocumentTypes.HIGHER_EDUCATION_CERTIFICATE,
    Führungszeugnis: enums.HelperDocumentTypes.CRIMINAL_RECORD,
    Kündigung: enums.HelperDocumentTypes.TERMINATION,
    'Aufenthaltstitel/Arbeitserlaubnis': enums.HelperDocumentTypes.RESIDENCE_PERMIT,
    'AU-Bescheinigung': enums.HelperDocumentTypes.AU_CERTIFICATE,
    'Vertrag anderer Job': enums.HelperDocumentTypes.OTHER_JOB_CONTRACT,
    Einkommensbescheinigung: enums.HelperDocumentTypes.INCOME_CERTIFICATE,
    'Brief vom Minijob': enums.HelperDocumentTypes.LETTER_FROM_MINI_JOB,
    'Brief vom Jobcenter': enums.HelperDocumentTypes.LETTER_FROM_THE_JOB_CENTER,
    Andere: enums.HelperDocumentTypes.OTHER,
  },
  clientDocumentTypesOBJ: {
    Vertrag: enums.ClientDocumentTypes.CONTRACT,
    'Abtretungserklärung(en)': enums.ClientDocumentTypes.DECLARATION_OF_ASSIGNMENT,
    Kündigung: enums.ClientDocumentTypes.TERMINATION,
    Verhinderungspflege: enums.ClientDocumentTypes.PREVENTION_CARE,
    Betreuerausweis: enums.ClientDocumentTypes.SUPERVISOR_ID,
    Andere: enums.ClientDocumentTypes.OTHER,
  },
  clientLetterTypesOBJ: {
    [enums.ClientLetterTypes.CONTRACT]: 'Vertrag',
    [enums.ClientLetterTypes.OTHER]: 'Andere',
  },
  invoiceLetterTypesObj: {
    [enums.InvoiceLetterTypes.INVOICE]: 'rechnung',
    [enums.InvoiceLetterTypes.INVOICE_REMINDER]: 'Rechnungserinnerung',
  },
  letterTypesOBJ: {
    [enums.LetterTypes.CLIENT_CONTRACT]: 'Vertrag',
    [enums.LetterTypes.INVOICE]: 'rechnung',
    [enums.LetterTypes.INVOICE_REMINDER]: 'Rechnungserinnerung',
    [enums.LetterTypes.MANUAL]: 'Manual',
    [enums.LetterTypes.OTHER]: 'Andere',
  },
  clientLetterStatusesOBJ: {
    [enums.ClientLetterStatuses.ACCEPTANCE_OF_THE_SHIPMENT]: 'Annahme der Sendung',
    [enums.ClientLetterStatuses.PROCESSING_OF_THE_SHIPMENT]: 'Verarbeitung der Sendung',
    [enums.ClientLetterStatuses.DELIVERY_TO_THE_PRINTING_CENTER]: 'Einlieferung in Druckzentrum',
    [enums.ClientLetterStatuses.PROCESSING_IN_PRINTING_CENTER]: 'Verarbeitung in Druckzentrum',
    [enums.ClientLetterStatuses.ERROR]: 'Verarbeitungsfehler',
  },
  clientStatusObj: {
    [enums.ClientStatus.NOT_INTERESTED]: 'Nicht Interessiert',
    [enums.ClientStatus.DRAFT]: 'Entwurf',
    [enums.ClientStatus.ACTIVE]: 'Activ',
    [enums.ClientStatus.INACTIVE]: 'Inaktiv',
    [enums.ClientStatus.TERMINATED]: 'Gekündigt',
    [enums.ClientStatus.FREEZED]: 'Freezed',
  },
  helperStatusObj: {
    [enums.HelperStatus.ACTIVE]: 'Aktiv',
    [enums.HelperStatus.INACTIVE]: 'Inaktiv',
    [enums.HelperStatus.TERMINATED]: 'Beendet',
  },
  interviewTypesObj: {
    [enums.InterviewTypes.YES]: 'Yes',
    [enums.InterviewTypes.NO_SHOW]: 'No Show',
    [enums.InterviewTypes.CANCELED]: 'Canceled',
  },
  confirmedTypesObj: {
    [enums.InterviewStatus.NO_SHOW_CONFIRMED]: 'Yes',
    [enums.InterviewStatus.NO_SHOW_NOT_CONFIRMED]: 'No',
  },
  decisionOptionsObj: {
    [enums.InterviewStatus.VERIFICATION]: 'Überprüfung',
    [enums.InterviewStatus.HELPER_DECLINED]: 'Helfer hat abgelehnt',
    [enums.InterviewStatus.DISCUSS]: 'Diskutieren',
    [enums.InterviewStatus.TOTALLY_DECLINED]: 'Total abgelehnt',
  },
  educationLevelObj: {
    [enums.EducationLevel.TRAINING]: 'Ausbildung',
    [enums.EducationLevel.BACHELOR]: 'Bachelor',
    [enums.EducationLevel.SCHOOL]: 'Schule',
    [enums.EducationLevel.MASTER]: 'Master',
  },
  vaccinationTypesObj: {
    [enums.VaccinationTypes.RECOVERED]: 'Genesen',
    [enums.VaccinationTypes['0']]: '0',
    [enums.VaccinationTypes['1']]: '1',
    [enums.VaccinationTypes['2']]: '2',
    [enums.VaccinationTypes['3']]: '3',
    [enums.VaccinationTypes['4']]: '4',
  },
  clientTasksObj: {
    [enums.ClientsTasks.NEEDS_HELPER]: 'Bedürfnisse Helfer',
    [enums.ClientsTasks.HAS_NO_CONTRACT]: 'Hat keinen Vertrag',
    [enums.ClientsTasks.REPLACEMENT]: 'Ersatzeinladung',
    [enums.ClientsTasks.NOT_READY_FOR_INVITATION]: 'Nicht bereit für die Einladung',
    [enums.ClientsTasks.CHECK_UP_CALL]: 'Anruf überprüfen',
  },
  InvoiceTypes: {
    EB_LAST_YEAR: 0,
    EB: 1,
    VP: 2,
    B_EB: 3,
    B_VP: 4,
    PRIVATE_EXTRA: 5,
    PRIVATE_ALL: 6,
  },
  publicInvoiceTypesFilterObj: {
    [enums.InvoiceTypes.EB]: 'Entlastungsbetrag',
    [enums.InvoiceTypes.VP]: 'Verhinderungspflege',
  },
  privateInvoicesStatusesFilter: {
    ...PrivateInvoicesStatusesObj,
  },
  invoiceTypesFilterObj: {
    ...invoiceTypesFilterObj,
  },
  invoiceTypesObj: {
    [enums.InvoiceTypes.EB_LAST_YEAR]: '< Entlastungsbetrag',
    [enums.InvoiceTypes.EB]: 'Entlastungsbetrag',
    [enums.InvoiceTypes.VP]: 'Verhinderungspflege',
    [enums.InvoiceTypes.PRIVATE_ALL]: 'Privat',
    [enums.InvoiceTypes.PRIVATE_EXTRA]: 'Eigentanteil',
    [enums.InvoiceTypes.B_EB]: 'Beihilfe EB',
    [enums.InvoiceTypes.B_VP]: 'Beihilfe VP',
  },
  invitationsTypesObj: {
    [enums.InvitationsTypes.STANDARD]: 'Standard',

    [enums.InvitationsTypes.REPLACEMENT]: 'Ersatz',
  },
  orderBy: {
    [enums.OrderBy.FIRST_NAME]: 'Vor Name',
    [enums.OrderBy.LAST_NAME]: 'Nach Name',
    [enums.OrderBy.CREATED_AT]: 'Hergestellt in',
  },
  statisticsHelpersOrderBy: {
    [enums.StatisticsHelpersOrderBy.FIRST_NAME]: 'Vor Name',
    [enums.StatisticsHelpersOrderBy.LAST_NAME]: 'Nach Name',
    [enums.StatisticsHelpersOrderBy.CREATED_AT]: 'Hergestellt in',
    [enums.StatisticsHelpersOrderBy.START_DATE]: 'Anfangsdatum',
    [enums.StatisticsHelpersOrderBy.INTERVIEW_DATE]: 'Interview Datum',
  },
  invoicesOrderBy: {
    [enums.InvoicesOrderBy.DOWNLOAD_COUNT]: 'Download-Zähler',
    [enums.InvoicesOrderBy.CREATED_AT]: 'Hergestellt in',
  },
  createInvitationReasons: {
    [enums.CreateInvitationReasons.HELPER_TERMINATED]: 'Helfer kündigt',
    [enums.CreateInvitationReasons.HELPER_VACATION]: 'Helfer ist im urlaub',
  },
  cancelInvitationReasons: {
    [enums.CancelInvitationReasons.WRONG_INVITATION]: 'Fehlerhafte Orderdaten',
    [enums.CancelInvitationReasons.CLIENT_TEMPORARILY_UNAVAILABLE]: 'Kunde vorübergehend verhindert',
    [enums.CancelInvitationReasons.OTHER]: 'Sonstige Gründe',
  },
  interviewCancelReasons: {
    [enums.InterviewCancelReasons.ANOTHER_JOB]: 'andere Arbeit',
    [enums.InterviewCancelReasons.NOT_INTERESTED]: 'nicht interessiert',
    [enums.InterviewCancelReasons.NO_TIME]: 'keine zeit',
    [enums.InterviewCancelReasons.OTHER]: 'Sonstiges',
  },
  exclusionCriteriaObj: {
    [enums.ExclusionCriteria.NO_LONG_TERM]: 'keine langfristige arbeit bei juhi',
    [enums.ExclusionCriteria.TOO_YOUNG]: 'Zu jung',
    [enums.ExclusionCriteria.TOO_OLD]: 'Zu alt',
    [enums.ExclusionCriteria.NO_KNOWLEDGE_OF_GERMAN]: 'keine deutschkenntnisse',
    [enums.ExclusionCriteria.NO_NEAT_APPEARANCE]: 'keine gepflegtes erscheinungsbild',
    [enums.ExclusionCriteria.MOTIVATION_DOES_NOT_FIT]: 'Motivation passt nicht',
    [enums.ExclusionCriteria.NOT_INFORMED]: 'Nicht informiert',
    [enums.ExclusionCriteria.LACK_OF_EXPERIENCE]: 'mangelnde Erfahrung/Reife',
  },
  positivePropertiesObj: {
    [enums.PositiveProperties.ACTIVE]: 'Aktiv',
    [enums.PositiveProperties.AMBITIOUS]: 'Ambitioniert',
    [enums.PositiveProperties.ATTENTIVE]: 'Aufmerksam',
    [enums.PositiveProperties.DISCIPLINED]: 'Diszipliniert',
    [enums.PositiveProperties.EHRGEIZIG]: 'Ehrgeizig',
    [enums.PositiveProperties.EXPERIENCED]: 'Erfahren',
    [enums.PositiveProperties.EXTROVERT]: 'Extrovertiert',
    [enums.PositiveProperties.INTROVERTED]: 'Introvertiert',
    [enums.PositiveProperties.HELPFUL]: 'Hilfsbereit',
    [enums.PositiveProperties.COMMUNICATIVE]: 'Kommunikativ',
    [enums.PositiveProperties.OPEN]: 'Offen',
    [enums.PositiveProperties.SELF_EMPLOYED]: 'Selbstständig',
    [enums.PositiveProperties.SELF_CONFIDENT]: 'Selbstsicher',
    [enums.PositiveProperties.SOCIAL]: 'Sozial',
    [enums.PositiveProperties.GROOMED]: 'Gepflegt',
    [enums.PositiveProperties.RELIABLE]: 'Zuverlässig',
    [enums.PositiveProperties.PUNCTUAL]: 'Pünktlich',
    [enums.PositiveProperties.LINGUISTIC]: 'Sprachlich begabt',
    [enums.PositiveProperties.FRIENDLY]: 'Sympathisch',
  },
  interviewStatusObj: {
    [enums.InterviewStatus.CANCELED]: 'Abgesagt',
    [enums.InterviewStatus.NO_SHOW_CONFIRMED]: 'Nichterscheinen bestätigt',
    [enums.InterviewStatus.NO_SHOW_NOT_CONFIRMED]: 'Nichterscheinen nicht bestätigt',
    [enums.InterviewStatus.DISCUSS]: 'Diskutieren',
    [enums.InterviewStatus.VERIFICATION]: 'Überprüfung',
    [enums.InterviewStatus.HELPER_DECLINED]: 'Helfer hat abgelehnt',
    [enums.InterviewStatus.TOTALLY_DECLINED]: 'Total abgelehnt',
  },
  clientCheckupCallObj: {
    [enums.ClientCheckupCallStatus.NO_CALL]: 'Kein Anruf',
    [enums.ClientCheckupCallStatus.NO_ANSWER]: 'Nicht erreicht',
    [enums.ClientCheckupCallStatus.VOICE_MAIL]: 'Auf AB gesprochen',
    [enums.ClientCheckupCallStatus.SATISFIED]: 'Zufrieden',
    [enums.ClientCheckupCallStatus.NOT_SATISFIED]: 'Unzufrieden',
  },

  invitationCheckupCallObj: {
    [enums.InvitationCheckupCallStatus.UNCLARIFIED]: 'Ungeklärt',
    [enums.InvitationCheckupCallStatus.CLIENT_CONTACTED]: 'Kunde kontaktiert',
    [enums.InvitationCheckupCallStatus.HELPER_CONTACTED]: 'Helfer kontaktiert',
  },
  notReachingTargetReasonObj: {
    [enums.NotReachingTargetReason.CLIENT_ON_VACATION]: 'Kunde war im Urlaub',
    [enums.NotReachingTargetReason.CLIENT_IN_HOSPITAL]: 'Kunde war krank (Krankenhausaufenthalt etc.)',
    [enums.NotReachingTargetReason.CLIENT_NO_NEED]: 'Kunde hatte keinen Bedarf',
    [enums.NotReachingTargetReason.HELPER_ON_VACATION]: 'Ich war im Urlaub',
    [enums.NotReachingTargetReason.HELPER_IN_HOSPITAL]: 'Ich war krank',
    [enums.NotReachingTargetReason.VISIT_PROBLEM]: 'Terminprobleme',
    [enums.NotReachingTargetReason.NO_MORE_TASKS]: 'Kunde hat keine weiteren Aufgaben für mich',
    [enums.NotReachingTargetReason.HAVE_NO_CAPACITY]: 'Ich habe keine Kapazitäten für weitere Termine',
    [enums.NotReachingTargetReason.CLIENT_NOT_WANT_USE_BUDGET]: 'Kunde möchte das Guthaben nicht nutzen',
    [enums.NotReachingTargetReason.OTHER]: 'Sonstige',
  },
  targetNotReachedTypeObj: {
    [enums.TargetNotReachedType.SERVICE_HOURS]: 'Service Plan',
    [enums.TargetNotReachedType.BUDGET]: 'Guthaben',
  },
  createNewClient: 'Neuen Kunden anlegen',
  months: {
    [enums.Months.JANUARY]: 'Januar',
    [enums.Months.FEBRUARY]: 'Februar',
    [enums.Months.MARCH]: 'März',
    [enums.Months.APRIL]: 'April',
    [enums.Months.MAY]: 'Mai',
    [enums.Months.JUNE]: 'Juni',
    [enums.Months.JULY]: 'Juli',
    [enums.Months.AUGUST]: 'August',
    [enums.Months.SEPTEMBER]: 'September',
    [enums.Months.OCTOBER]: 'Oktober',
    [enums.Months.NOVEMBER]: 'November',
    [enums.Months.DECEMBER]: 'Dezember',
  },

  terminationReasonCategoriesObj: {
    [TerminationReasonCategories.INTERNAL]: 'Intern Gründe',
    [TerminationReasonCategories.EXTERNAL]: 'Extern Gründe',
    [TerminationReasonCategories.UNCLEAR]: 'Unklar Gründe',
    [TerminationReasonCategories.NEW_CLIENT_NOT_INTERESTED]: 'Neuer Kunde nicht interessiert',
  },

  clientTerminationReasonsObj: {
    [TerminationReasonCategories.INTERNAL]: {
      [ClientTerminationReasons[TerminationReasonCategories.INTERNAL].BILLING]: 'Unzufrieden (Abrechnung)',
      [ClientTerminationReasons[TerminationReasonCategories.INTERNAL].MATCHING]: 'Unzufrieden (Zuteilung)',
      [ClientTerminationReasons[TerminationReasonCategories.INTERNAL].HELPER_QUALITY]: 'Unzufrieden (Helferqualität)',
      [ClientTerminationReasons[TerminationReasonCategories.INTERNAL].SUPPORT]: 'Unzufrieden (Kundenservice)',
    },
    [TerminationReasonCategories.EXTERNAL]: {
      [ClientTerminationReasons[TerminationReasonCategories.EXTERNAL].CLIENT_DIED]: 'Kunde ist verstorben',
      [ClientTerminationReasons[TerminationReasonCategories.EXTERNAL].MOVE_TO_NURSING_HOME]:
        'Umzug in Pflegeheim/Hospiz',
      [ClientTerminationReasons[TerminationReasonCategories.EXTERNAL].TERMINATED_BY_JUHI]: 'Von JUHI gekündigt',
      [ClientTerminationReasons[TerminationReasonCategories.EXTERNAL].TEMPORARY_ORDER]: 'Befristeter Auftrag',
      [ClientTerminationReasons[TerminationReasonCategories.EXTERNAL].MISSING_SERVICE_COVERAGE_LOCATION]:
        'Fehlende Serviceabdeckung (Leistungsort)',
      [ClientTerminationReasons[TerminationReasonCategories.EXTERNAL].MISSING_SERVICE_COVERAGE_TYPE]:
        'Fehlende Serviceabdeckung (Art der Dienstleistung)',
    },
    [TerminationReasonCategories.UNCLEAR]: {
      [ClientTerminationReasons[TerminationReasonCategories.UNCLEAR].LONG_INACTIVITY]:
        'Lange Inaktivität - Kontaktaufnahme blieb erfolglos',
      [ClientTerminationReasons[TerminationReasonCategories.UNCLEAR].OTHER]: 'Sonstiges',
    },
    [TerminationReasonCategories.NEW_CLIENT_NOT_INTERESTED]: {
      [ClientTerminationReasons[TerminationReasonCategories.NEW_CLIENT_NOT_INTERESTED].NO_MORE_INTEREST]:
        'NK - Kein Interesse mehr',
    },
  },

  clientNotInterestedTerminationReasonObj: {
    [TerminationReasonCategories.NEW_CLIENT_NOT_INTERESTED]: {
      [ClientTerminationReasons[TerminationReasonCategories.NEW_CLIENT_NOT_INTERESTED].NO_MORE_INTEREST]:
        'NK - Kein Interesse mehr',
    },
  },

  helperTerminationReasonsObj: {
    [TerminationReasonCategories.INTERNAL]: {
      [HelperTerminationReasons[TerminationReasonCategories.INTERNAL].ORDER_SUPPLY]: 'Unzufrieden (Auftragslage)',
      [HelperTerminationReasons[TerminationReasonCategories.INTERNAL].TASKS]: 'Unzufrieden (Tätigkeiten)',
      [HelperTerminationReasons[TerminationReasonCategories.INTERNAL].CLIENTS]: 'Unzufrieden (Kunden)',
      [HelperTerminationReasons[TerminationReasonCategories.INTERNAL].SUPPORT]: 'Unzufrieden (Helfer Support)',
      [HelperTerminationReasons[TerminationReasonCategories.INTERNAL].PAYMENT]: 'Unzufrieden (Bezahlung)',
    },
    [TerminationReasonCategories.EXTERNAL]: {
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].TIME_AVAILABILITY_SCHOOL]:
        'Zeitliche Verfügbarkeit (Schule)',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].TIME_AVAILABILITY_VOCATIONAL_TRAINING]:
        'Zeitliche Verfügbarkeit (Ausbildung)',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].TIME_AVAILABILITY_STUDIES]:
        'Zeitliche Verfügbarkeit (Studium)',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].TIME_AVAILABILITY_FSJ]:
        'Zeitliche Verfügbarkeit (FSJ, FÖJ, BFD o.Ä.)',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].TIME_AVAILABILITY_OTHER]:
        'Zeitliche Verfügbarkeit (Sonstiges)',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].PRIVATE_CIRCUMSTANCES]: 'Private Gründe',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].TERMINATED_BY_JUHI]: 'Von JUHI gekündigt',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].MOVE_TO_ANOTHER_CITY]:
        'Umzug in eine andere Stadt',
      [HelperTerminationReasons[TerminationReasonCategories.EXTERNAL].NO_MORE_INTEREST]:
        'Neuer Helfer - Kein Interesse mehr ',
    },
    [TerminationReasonCategories.UNCLEAR]: {
      [HelperTerminationReasons[TerminationReasonCategories.UNCLEAR].LONG_INACTIVITY]:
        'Lange Inaktivität - Kontaktaufnahme blieb erfolglos',
      [HelperTerminationReasons[TerminationReasonCategories.UNCLEAR].OTHER]: 'Sonstiges',
    },
  },
  ClientContractLaterReasonsObj: {
    [enums.ContractLaterReasons.CONTRACT_NOT_RECEIVED]: 'Vertrag nicht angekommen',
    [enums.ContractLaterReasons.CONTRACT_FAULTY]: 'Vertrag fehlerhaft',
    [enums.ContractLaterReasons.RESEND_REQUESTED]: 'Neuzusendung gewünscht',
    [enums.ContractLaterReasons.OTHER]: 'Sonstiges',
  },
  helperLevelObj: {
    [enums.HelperLevel.BRONZE]: 'Bronze',
    [enums.HelperLevel.SILVER]: 'Silber',
    [enums.HelperLevel.GOLD]: 'Gold',
    [enums.HelperLevel.DIAMOND]: 'Diamant',
  },

  servicePlanNameObj: {
    [enums.ServicePlan.BASIC]: 'Einstieg',
    [enums.ServicePlan.BEST_CHOICE]: 'Standard',
    [enums.ServicePlan.PREMIUM]: 'Premium',
  },
  helperDocumentsTypesObj: {
    [enums.HelperDocumentTypes.CRIMINAL_RECORD]: 'Criminal Record',
    [enums.HelperDocumentTypes.HIGHER_EDUCATION_CERTIFICATE]: 'Higher Education Certificate',
  },
  tasksCompletedWithinTimeObj: {
    [enums.TasksCompletedWithinTime.NO]: 'NEIN',
    [enums.TasksCompletedWithinTime.RATHER_NO]: 'Eher nein',
    [enums.TasksCompletedWithinTime.NEUTRAL]: 'neutral',
    [enums.TasksCompletedWithinTime.RATHER_YES]: 'Eher ja',
    [enums.TasksCompletedWithinTime.YES]: 'Ja',
  },
  serviceTypeAnswersObj: {
    [enums.ServiceTypeAnswers.HOME_HELP]: 'Haushaltshilfe',
    [enums.ServiceTypeAnswers.SHOPPING]: 'Einkaufshilfe',
    [enums.ServiceTypeAnswers.CARE_ACCOMPANIMENT]: 'Betreuung / Begleitung',
    [enums.ServiceTypeAnswers.COMBINATION]: 'Kombination (der oberen 3) ',
    [enums.ServiceTypeAnswers.OTHER]: 'Sonstiges',
  },
  adminInvitationCreationReasonObj: {
    [enums.AdminInvitationCreationReason.HELPER_CHANGE_CLIENT_REQUEST]: 'Helferwechesel (Kundenwunsch)',
    [enums.AdminInvitationCreationReason.HELPER_CHANGE_HELPER_REQUEST]: 'Helferwechsel (Helferwunsch)',
    [enums.AdminInvitationCreationReason.HELPER_CHANGE_BLACKLIST_HELPER]: 'Helferwechsel (Blacklist Helfer)',
    [enums.AdminInvitationCreationReason.ADD_HELPER_BUDGET_USAGE]: 'Zusätzlicher Helfer (Guthabennutzung)',
    [enums.AdminInvitationCreationReason.ADD_HELPER_OTHER_TASKS]: 'Zusätzlicher Helfer (Andere Aufgaben) ',
    [enums.AdminInvitationCreationReason.INITIAL_ALLOCATION_NK]: 'Erstzuteilung (NK)',
    [enums.AdminInvitationCreationReason.REPLACEMENT]: 'Ersatzauftrag',
    [enums.AdminInvitationCreationReason.OTHER]: 'Sonstiges',
  },

  rules: {
    termsOfParticipation: {
      certificate: 'Dokumente vollständig hochgeladen (Führungszeugnis & Schulbescheinigung)',
      profileCompleted: 'Erster Besuch bestätigt',
    },
    personalQuests: {
      visitAtLeastTwoClients: 'Betreue mind. 2 Kunden',
      fulfilTheVisitPlansOfAllClients: 'Erfülle den Serviceplan von allen Kunden',
      submitAllBillingOnTime: 'Schließe die Abrechnung von allen Kunden pünktlich ab',
      submitAllVisitReportsOnTime: 'Reiche alle Besuchsberichte pünktlich ein',
    },
    milestones: {
      juhiCrown: 'JUHI-Krone',
      juhiBox: 'JUHI-Box',
    },
    levels: {
      bronzeAtLeastTwoClientsBilled: 'Bronze: Mindestens 2 Kunden abgerechnet',
      sliver50Hrs: 'Silber: 50 Stunden',
      gold150Hrs: 'Gold: 150 Stunden',
      diamond300Hrs: 'Diamant: 300 Stunden',
    },
    achievements: {
      normalPraise: 'Normales Lob',
      extraordinarPraise: 'Außerordentliches Lob',
      reactToOrderWithin24Hrs: 'Reagieren Sie innerhalb von 24 Stunden auf eine Bestellung',
      planVisitWithin24HrsAfterAcceptation:
        'Trage innerhalb von 24h den ersten Besuch eines neuen Auftrages im Kalender ein',
      dailyLogInReward: 'Tägliche Log In Belohnung',
      pushNotificationsWhereTurnedOnForTheWholeMonth:
        'Lasse die Push Benachrichtigungen für den gesamten Monat eingeschaltet',
      hourlyRewardPreHourOnlyConfirmedVisits: 'Belohnung für jede geleistete Stunde (nur bestätigte Termine)',
      helperRecommended: 'Helfer empfohlen',
      clientRecommended: 'Kunde empfohlen',
      complaintOnlyWithReason: 'Beschwerde (nur mit Grund)',
      bigComplaintOnlyWithReason: 'Große Beschwerde (nur mit Grund)',
      useFullServicePlanBudgetForaClient: 'Nutze den gesamten Serviceplan + Guthaben für einen Kunden',
    },
    rules: {
      ifThereIsaTieFirstleTheMonthlyBestalueOfTheseHelpersAraComparedSecondlyTheDurationOfEpmloymen:
        'Die Punkte deiner Persönlichen Quests werden zu den Punkten deiner Errungenschaften addiert und ergeben das monatliche Endergebnis.',
      noNegativeScoresArePossible: 'Die Summe deiner monatlichen Endergebnisse bildet dein Jahresergebnis.',
      scoresAreAlwaysSavedEvenIfTheAnnualRankingIsRestarted:
        'Gibt es ein Gleichstand im Ranking, entscheidet die Dauer der Beschäftigung bei JUHl.',
    },
  },
  vacationDates: vacation =>
    `Start Datum: ${moment(vacation.startDate).format('DD.MM.YYYY')}  End Datum: ${moment(vacation.endDate).format(
      'DD.MM.YYYY',
    )}`,
  achievements: 'Errungenschaften',
  termsOfParticipation: 'Teilnahmebedingungen',
  personalQuests: 'Persönliche Quests',
  milestones: 'Meilensteine',
  levels: 'Levels',
  Rules: 'Regeln',
  ///// Personal Data
  personalData: 'Persönliche Daten',
  salutation1: 'Anrede',
  salutation2: 'Titel',
  firstName: 'Vorname',
  lastName: 'Nachname',
  birthday: 'Geburtsdatum',
  street: 'Straße',
  buildingNumber: 'Nummer',
  zipCode: 'Postleitzahl',
  city: 'Ort',
  addressExtra: 'Vesandadresse',
  mailAddressTooltip:
    'Falls der Bedarfsort von der Versandadresse abweicht, dann trage hier bitte die Versandadresse ein.',
  district: 'Bezirk',
  addMailAddress: 'Mail-Adresse hinzufügen',
  removeMailAddress: 'Mail-Adresse entfernen',
  //////// Contact Data
  contactData: 'Kontaktdaten',
  phone: 'Telefonnummer',
  email: 'E-Mail',
  allowNotificationEmails: 'Benachrichtigungs-E-Mails zulassen',
  emergencyNumber: 'Notfallkontakt',
  emergencyNumberTooltip: 'Dieses Feld ist nur Intern zu sehen.',
  contactPerson: 'Kontaktperson',
  contactPersonEmail: 'Kontaktperson E-Mail',
  blocked: 'Für Zuteilung blockiert',
  salesCallAdmin: 'Anruf durchgeführt durch',
  showPersonalInfo: 'anzeigen',
  showPersonalInfoTooltip:
    'Wenn sich der Kunde wünscht, dass wir ihn vor der Zuteilung kontaktieren, dann setze das Feld auf nein.',
  ////////// billing Details
  billingDetails: 'Rechnungsdaten',
  admissionDate: 'Aufnahmedatum',
  admissionDateTooltip: 'Aufnahmedatum ist der Tag an dem das Kundengespräch geführt wurde.',
  nursingInsuranceNumber: 'IK-Nummer Pflegekasse',
  ikNumber: 'IK-Nummer Krankenkasse',
  ikNumberTooltip: 'Beginnt mit 10',
  insuranceNumber: 'Versichertennummer',
  shortTermCare: 'Kurzzeitpflege',
  govAid: 'Beihilfe',
  ebAmountPercentage: 'Entlastungsbetrag (%)',
  vpAmountPercentage: 'Verhinderungspflege (%)',
  privateExtraAmountPercentage: 'Privat Extra Betrag (%)',
  wantInvoiceCopy: 'WOLLEN SIE Rechnungskopie',
  wantInvoiceByPost: 'per Post',
  wantInvoiceByEmail: 'per E-Mail',
  wantInsuranceCompanyInvoiceCopy: 'WOLLEN SIE Rechnungskopie von der Krankenkasse',
  invoiceCopy: 'Rechnungskopie',
  privateInsurance: 'Privatversichert',
  sendInvoicesToClient: 'Senden Sie Rechnungen an den Kunden',
  otherStartDate: 'Anderes Startdatum',
  otherStartDateTooltip: 'Wünscht sich der Kunde ein späteren Start, der auch im Vertrag vermerkt werden soll?',
  rededicationVp: 'Umwidmung der Pflegesachleistungen',
  appplyVp: 'Sollen wir die VP beantragen?',
  interestInVp: 'Findet der Kunde die VP für später grundsätzlich interessant?',
  percentage: 'Prozentsatz',
  vpExtraAcceptedYears: 'Kurzzeitpflege -Startdatum',
  shortTermCareYearsLabel: 'Für welches Jahr wurde die Kurzzeitpflege beantragt?',
  preventiveCareAcceptedLabel: 'Für welches Jahr wurde die Verhinderungspflege beantragt?',
  onlyUseVp: 'Verwenden Sie nur VP',
  changePercentage: 'Prozentsatz ändern',
  applyVpTooltip:
    "Die oben angezeigten Budget sind inkl. der Verhinderungspflege. Sollte der Kunde die Verhinderungspflege nicht nutzen wollen, dann setze dieses Feld auf 'Nein'.",
  privateInsuranceTooltip:
    'Wenn der Kunde keine normale Versichertennummer Buchtabe + 9 Ziffern oder eine normale IK-Nummer die mit 10 beginnt, dann ist er privat versichert. Die Kunden wissen das eigentlcih selbst auch.',
  insuranceNumberTooltip: 'Buchstabe + 9 Ziffern',
  sendInvoicesToClientTooltip:
    'Möchte der Kunde die Rechnungen selbst bei der Krankekasse einreichen? Bei Privatversicherten ist es automatisch auf Ja gesetzt, da wir nicht mit der Pflegekasse abrechnen können.',
  wantInvoiceCopyTooltip: 'Wie sollen Rechnungen (bspw. Eigenanteil) versendet werden?',
  wantInsuranceCompanyInvoiceCopyTooltip: 'Möchte der Kunde die Rechnung der Versicherungsgesellschaft erhalten?',
  ////////// Care Data
  careData: 'Pflegedaten',
  pensionPermisson: 'Vorsorgevollmacht',
  notes: 'Bemerkungen',
  internal: 'Intern',

  //////////// Billing Information
  billingInfo: 'Abrechnungsdaten',
  careLevel: 'Was ist der aktuelle Pflegegrad?',
  since: 'Wann ist der erste zugesprochene Pflegegrad erteilt?',
  sinceTooltip:
    'Sollte der Kunde nicht das genau Datum nennen, dann bitte fragen in welcher Jahreszeit. Nehme dann den letzten Monat. Zum Beispiel: Winter: dann 01.03.2022. Das genaue Datum soll der Kunde bitte umgehend nachreichen.',
  careLevelTwoOrHigherSince: 'Wann wurde eine PG >= 2 erstmalig zugesprochen',
  noCareLevel: 'Kein Pflegegrad',
  otherServiceProviderNow: 'Aktuell einen anderen Dienstleister',
  receivesEbFromSocialOffice: 'Erhält EB vom Soziamt',
  oldServiceProvider: 'Vorher einen anderen Dienstleister',
  to: 'Bis',
  currentBudget: 'EB Aktuelles Budget',
  lastYearBudget: 'EB Budget des letzten Jahres',
  lastYearBudgetTooltip: 'Sollte der Kunde das genaue Budget kennen, dann bitte hier eintragen.',
  preventiveCareAccepted: 'Verhinderungspflege beantragt',
  preventiveCareUsed: 'Verhinderungspflege verwendet',
  vpAcceptedYears: 'Für welches Jahr soll die VP freigeschaltet werden?',
  vpCurrentBudget: 'VP Aktuelles Budget',
  vpCurrentBudgetTooltip:
    'Wenn hier nichts eingegeben wird, dann rechnet die Software sicherheitshalber damit, dass alles verwendet wurde.',
  amount: 'Summe',
  ebBudget: 'Entlastungsbetrag Budget',
  from: 'Von',
  ownShare: 'Eigenanteil',
  division: 'Aufteilung',
  budgetTooltip:
    'Hier werden die Budgets des Kunden angezeigt. Nutze diese um den Kunden einen Buchungsplan mit maximalen Stunden zu erstellen.',
  ////////////// Service Data
  services: 'Aufträge',
  serviceData: 'Auftragsdaten',
  serviceNotes: 'Notizen zum Auftrag',
  vaccination: 'Impfung',
  restrictions: 'Einschränkungen',
  restriction: 'Einschränkung',
  support: 'Unterstützung',
  pets: 'Haustiere',
  recommendedByClients: 'Vom Kunden empfohlen',
  recommendedByHelpers: 'Von Helfer empfohlen',
  thePets: 'Die Haustiere',
  smokingHousehold: 'Raucherhaushalt',
  driversLicense: 'Führerschein',
  allergies: 'Allergien',
  allergy: 'Allergie',
  accommodationSituation: 'Wohnsituation',
  accommodationSituations: 'Wohnsituation',

  numberOfHours: 'Stundenanzahl',
  per: 'Pro',
  informOnHelperChange: 'Bei Helferwechsel informieren',
  smoker: 'Raucher',
  serviceNotesTooltip: 'Diese Beschreibung ist für den Helfer in der App sichtbar.',
  notesTooltip: 'Diese Beschreibung ist nur Intern und nicht für den Helfer sichtbar.',
  //////// Preferences
  preferences: 'Präferenzen',
  preferredTimes: 'Zeitliche Präferenz',
  preferredTime: 'Zeitliche Präferenz',
  availableDay: 'Verfügbarkeit',
  addPreferredTime: 'Zeitliche Präferenz hinzufügen',
  removePreferredTime: 'Zeitliche Präferenz entfernen',
  preferredGender: 'Gewünschtes Geschlecht',
  preferredHelperSituation: 'Gewünschtes Helferstatus',
  preferredVaccination: 'Gewünschtes Impfstatus des helfers',
  helperUserLanguage: 'Sprache des Helfers',
  preferredGenderTooltip:
    'Wähle hier das Wunschgeschlecht des Kunden aus. Die Zuteilung für männliche Kunden, die eine Helferin wollen,  dauert in der Regel länger.',
  preferredHelperLevel: 'Gewünschtes Helferlevel',
  //////// Miscellaneous
  miscellaneous: 'Sonstiges',
  marketingChannel: 'MarketingKanal',
  recommendedBy: 'Empfohlen durch',
  client: 'Kunde',
  helper: 'Helfer',
  other: 'Sonstige',
  create: 'Hinzufügen',
  edit: 'Bearbeiten',
  delete: 'Löschen',
  dropdownValues: 'Dropdown-Werte',
  admins: 'Admins',
  createAdmin: 'Admin erstellen',
  editAdmin: 'Admin bearbeiten',
  admin: 'Admin',
  insuranceCompanies: 'Krankenkassen',
  createInsuranceCompany: 'Krankenkasse erstellen',
  editInsuranceCompany: 'Krankenkasse bearbeiten',
  insuranceCompany: 'Krankenkasse',
  createService: 'Auftrag erstellen',
  service: 'Auftrag',
  editService: 'Auftrag bearbeiten',
  serviceCategories: 'Auftrag Kategorien',
  createServiceCategory: 'Auftrag Kategorien erstellen',
  serviceCategory: 'Auftrag Kategorie',
  editServiceCategory: 'Auftrag Kategorie bearbeiten',
  errorWhileLoading: 'Fehler beim Laden',
  anErrorHasOccurred: 'Ein Fehler ist aufgetreten',
  search: 'Suche',
  deletedSuccessfully: 'Erfolgreich gelöscht',
  deleteConfirmationQuestion: name => `Möchten Sie "${name}" wirklich löschen?`,
  cancel: 'Abbrechen',
  success: 'Erfolg',
  nursingAddress: 'Pflegeadresse',
  insuranceCompanyNumber: 'Krankenkasse Nummer',
  number: 'Nummer',
  fixErrorsAndSubmitAgain: 'Bitte beheben Sie die obigen Fehler und senden Sie sie erneut.',
  createdSuccessfully: 'Erfolgreich erstellt',
  editedSuccessfully: 'Erfolgreich bearbeitet',
  editHeader: name => `${name} bearbeiten`,
  createHeader: name => `Neuen ${name} anlegen`,
  createAllergy: 'Allergie erstellen',
  editAllergy: 'Allergie bearbeiten',
  id: 'ID',
  name: 'Name',
  createRestriction: 'Einschränkung erstellen',
  editRestriction: 'Einschränkung bearbeiten',
  createSupport: 'Unterstützung erstellen',
  editSupport: 'Unterstützung bearbeiten',
  time: 'Zeit',
  editTime: 'Zeit bearbeiten',
  createTime: 'Zeit erstellen',
  createAccommodationSituation: 'Wohnsituation erstellen',
  editAccommodationSituation: 'Wohnsituation bearbeiten',
  pet: 'Haustier',
  createPet: 'Haustier erstellen',
  editPet: 'Haustier bearbeiten',
  yes: 'Ja',
  no: 'Nein',
  ///////////////// Helper
  nationalities: 'Staatsangehörigkeiten',
  createNewHelper: 'Neuen Helfer anlegen',
  status: 'Status',
  start: 'Beginn',
  end: 'Ende',
  contractType: 'Vertragsart',
  pensionInsuranceFeePayer: 'Rentenversicherungsbeitrag',
  freed: ' befreit',
  notFreed: 'nicht befreit',
  taxId: 'Steuer-ID',
  pensionInsuranceNumber: 'Rentenversicherungsnummer',
  otherJob: 'Weitere Beschäftigungen',
  hourlyWage: 'Stundenlohn',
  startDate: 'Aufnahmedatum',
  endDate: 'Ende',
  invitationStartDate: 'Beginn',
  jobCenter: 'Job Center',
  housingBenefit: 'Wohngeld',
  highestSchoolDegree: 'Höchster Schulabschluss',
  highestWorkDegree: 'Höchster Ausbildungsabschluss',
  interviewDate: 'Bewerbungsgespräch',
  healthInsuranceType: 'Art',
  healthInsurance: 'Krankenversicherung',
  noContribution: 'Kein Beitrag',
  socialSecurityCalculation: 'SV Berechnung',
  pensionInsurance: 'Rentenversicherung',
  careInsurance: 'Pflegeversicherung',
  payable: 'Umlagepflichtig',
  transitionArea: 'Übergangsbereich',
  uMandatory: 'Umlagepflichtig',
  hazardPayScale: 'Gefahrtarifstelle',
  hazardPayScaleDescription: '5186676:0661 mobile soziale Dienste ohne pflegerische Leistungen',
  taxLevel: 'Steuerklasse',
  importantInformation: 'Wichtige Informationen',
  noSmokingHousehold: 'Kein Raucherhaushalt',
  type: 'Klasse',
  typeInvitation: 'Typ',
  userLanguages: 'Sprachen',
  language: 'Sprache',
  fearOfPets: 'Angst vor Haustieren',
  whichPets: 'Welche Haustiere',
  typeOfService: 'Art der Dienstleistung',
  workDistricts: 'Tätigkeitsbereich',
  workHoursPerWeekFrom: 'Gewünschte Arbeitszeit pro Woche von',
  workHoursPerWeekTo: 'Gewünschte Arbeitszeit pro Woche bis',
  birthCity: 'Geburtsort',
  birthCountry: 'Geburtsland',
  applyPreferredHelperGender: 'Helfer, die ausschließlich dieses Kundengeschlecht bevorzugen',
  applyPreferredClientGender: 'Kunden bevorzugtes Geschlecht',
  matchPetsPreference: 'Haustieren',
  matchSmokingHouseholdPreference: 'Raucher',
  matchPreferredTime: 'Zeit',
  hasDriversLicense: 'Führerschein',
  applyPreferredVaccination: ' Impfung',
  matchLanguage: 'Sprache',
  filters: 'Präferenzen',
  yearsOld: 'Jahre alt',
  previouslyWorkedWith: (number, type) =>
    number > 0
      ? `Bisher mit ${number} ${type === 'helper' ? (number > 1 ? 'Helfern' : 'Helfer') : ''} gearbeitet`
      : 'Diesem Kunden wurden noch keine Helfer zugeteilt',
  all: 'Alle',
  proficiency: 'Sprachniveau',
  level: 'Level',
  levelOneSince: 'Level eins seit',
  ebAvailableBudget: 'Verfügbares Entlastungsbetragbudget',
  vpAvailableBudget: 'Verfügbares Verhinderungspflegebudget',
  validStartDate: 'Ausstellungsdatum',
  validEndDate: 'Gültigkeitsdauer',
  document: 'Dokumentieren',
  workLocation: 'Standort',
  filterHasHelper: {
    [enums.ActiveHelpers.HAS_HELPER]: 'Ja',
    [enums.ActiveHelpers.HAS_NO_HELPER]: 'Nein',
  },
  filterShowPhoneNumber: {
    [enums.ShowPersonalInfo.SHOW_PHONE_NUMBER]: 'Ja',
    [enums.ShowPersonalInfo.NOT_SHOW_PHONE_NUMBER]: 'Nein',
  },
  hasHelper: 'Hat Helfer',
  showPhoneNumber: 'Telefonnummer anzeigen',
  invoiceType: 'Rechnungsart',
  noPreference: 'Keine Präferenz',
  notSetYet: 'Noch nicht festgelegt',
  few: 'Wenige',
  // Helper Interview
  educationLevel: 'Studium/Ausbildung/Schule',
  futurePlans: 'Pläne für die Zukunft',
  spendFreeTime: 'Wie verbringst du deine Freizeit',
  isFirstJob: 'Würde dieser Job dein erster Job sein?',
  previousPartTimeJobs: 'Anzahl vorherige Nebenjobs',
  gainWorkExperience: 'Wo konntest du schon Arbeitserfahrungen sammeln',
  activitiesExperiences: 'Was waren deine Tätigkeiten / positive & negative Erfahrungen',
  importantForWork: 'Was glaubst du ist wichtig für die Arbeit als Helfer/in ?',
  personalGoals: 'Motivation: Persönliche Ziele durch den Job bei JUHI',
  socialFieldExperience: 'Hast du Erfahrungen im sozialen Bereich sammeln können:',
  which: 'Welche',
  whyHireYou: 'Warum denkst du, sollten wir dich einstellen?',
  dealWithDifficultSituation: 'Wie gehst du mit einer schwierigen Situation/Problemen um?',
  howLongWorking: 'Wie lange hast du dir vorgestellt bei uns zu arbeiten? (in Monate)',
  positiveProperties: 'Positive Eigenschaften: max. 5 auswählbar',
  exclusionCriteria: 'Ausschlusskriterien',
  personalAssessment: 'personalAssessment',
  notSpecified: 'Unbestimmt',
  helperAssessment: 'Helferbewertung',
  whatAreYouLookingFor: 'Was suchen Sie?',
  searchAndApplyFilters: 'Suchen und Filter anwenden',
  mailAddress: 'Versandadresse',
  powerOfAttorney: 'Vorsorgevollmacht',
  homeTelephone: 'Festnetz',
  hours: 'Stunden',
  required: 'Erforderlich',
  notRequired: 'Nicht Erforderlich',
  startDateErrMsg: 'Startdatum muss vor dem Enddatum sein',
  endDateErrMsg: 'Enddatum muss nach dem Startdatum erfolgen',
  addPlan: 'Plan hinzufügen',
  removePlan: 'Plan entfernen',
  howToReceiveBill: 'Wie wollen Sie die Rechnung erhalten?',
  validate: 'Bestätigen',
  moveToClients: 'Zu Kunden wechselns',
  suggestPlans: 'Pläne vorschlagens',
  suggestionsAdded: 'Vorschläge wurden basierend auf Ihrem Budget hinzugefügt',
  rejectInvitationTooltip: `Dieser Helfer hat den Auftrag von diesen Kunden abgelehnt. Du kannst diese Ablehnung behebn um ihm nochmal den Auftrag zu schicken`,
  helperRejectClientPending: `Dieser Helfer hat den Auftrag <span style="color:${dangerColor[0]};">abglehnt</span>. Der Auftrag ist aber <span style="color:#4285F4;">noch offen</span> für andere Helfer.
  Für mehr Info geh auf die`,
  clientSide: `Kunden Seite`,
  helperRejectClientAccept: `Dieser Helfer hat den Auftrag <span style="color:${dangerColor[0]};">abgelehnt</span>.
  Aber ein anderer Helfer hat den Auftrag <span style="color:${primaryColor[0]};">angenommen</span>.`,
  helperRejectClientReject: `Alle Helfer haben den Auftrag von diesen Kunden <span style="color:${dangerColor[0]};">abglehent</span>`,
  helperExpiredClientAccepted: `Ein anderer Helfer hat den Auftrag vor diesem Helfer <span style="color:${primaryColor[0]};">angenommen</span>.`,
  helperAcceptedDeleted: `Dieser Auftrag <span style="font-weight: bold; text-decoration: underline;">war</span> <span style="color:${primaryColor[0]};">angenommen</span>. Es wurde aber den Helfer entzogen`,
  helperRejectedDeleted: `Dieser Auftrag war von Helfer <span style="color:${dangerColor[0]};">abgelehnt. Diese Ablehnung</span> wurde aber gelöscht um ihm nochmal den Auftrag zu schicken`,
  applyCanAcceptClientsMarkers: 'Helferkapazitäten',
  removeHelpersThatCantAcceptClients: 'Helfer ohne Kapazitäten',
  removeTerminatedHelpers: 'Gekündigte Helfer',
  removeDeclinedHelpers: 'von Helfer abgelehnt',
  removeClients: 'Alle Kunden ausblenden',
  idleHelpers: 'Helfer ohne Auftrag',
  noAcceptedInvitation: 'Keine Bestätigung',
  hasAcceptedInvitation: 'Hat Bestätigung',
  invitationIsRejected: 'Bestätigung abgelehnt',
  invitationIsAccepted: 'Bestätigung angenommen',
  invitationIsPending: 'Bestätigung ausstehend',
  notInvited: 'Nicht eingeladen',
  markers: 'Markierungen',
  newHelper: 'Neuer Helfer',
  download: 'Download',
  fileType: 'Dateityp',
  colored: 'Farbig',
  front: 'Vorderseite',
  and: 'und',
  back: 'Zurück',
  title: 'Titel',
  description: 'Beschreibung',
  bugAttachments: 'Bug-Anhänge',
  priority: 'Priorität',
  formatNotSupported: 'Format wird nicht unterstützt',
  expectationsJUHI: 'Erwartungen an JUHI',
  noDocuments: 'Keine Dokumente',
  noSignatures: 'Keine Unterschriften',
  declineModalMessage:
    'Sind Sie sicher, dass Sie nur diese Besuche ablehnen möchten, wenn Sie bestätigt haben, dass Sie keine Besuche für diesen Kunden ablehnen können',
  declineModalTitle: 'Bestätigen Sie rückläufige Besuche',
  proceed: 'Fortfahren',
  declineNote: 'Rückgangsnote',
  decline: 'Ablehnen',
  noClients: 'Kein Kunde',
  previousClient: 'Vorheriger Kunde',
  nextClient: 'Nächster Kunde',
  downloadCount: 'downloadCount',
  createdAt: 'Hergestellt in',
  deletedAt: 'Gelöscht bei',
  completedAt: 'Abgeschlossen bei',
  invoiceNo: 'Invoice No.',
  includeImages: 'Bilder einschließen',
  visits: 'Termin',
  noVisitsDeclined: 'Sie haben keine Besuche abgelehnt',
  unlock: 'Freischalten',
  lock: 'Sperren',
  formErr: 'Bitte beheben Sie die Fehler und senden Sie erneut.',
  helpers: 'Helferinnen',
  clientName: 'Kundenname',
  endTime: 'Endzeit',
  startTime: 'Startzeit',
  bonus: 'Bonus',
  moreDetails: 'Mehr Details',
  billed: 'In Rechnung gestellt',
  confirmPayment: 'Bestätige Zahlung',
  details: 'Einzelheiten',
  totalAmount: 'Gesamtmenge',
  hoursMonth: 'Stunden/Monat',
  helperName: 'Helfername',
  notYet: 'Noch nicht',
  paid: 'Bezahlt',
  bill: 'Rechnung',
  notConfirmed: 'Nicht bestätigt',
  confirmed: 'Bestätigt',
  year: 'Jahr',
  month: 'Monat',
  otherDocuments: 'Andere Dokumente',
  allDocuments: 'Alle Dokumente',
  acceptedAt: 'Akzeptiert bei',
  targetNotReachedReason: 'Ziel wurde nicht erreicht, weil,',
  info: 'Info',
  endDateSet: 'Enddatum ist festgelegt',
  endDateSetSuccessfully: 'Einladung, die am Ende des Monats gelöscht werden soll',
  close: 'Schließen',
  startInvitationLoop: 'Starten Sie die Einladungsschleife',
  confirmStartInvitation:
    'Du bist gerade dabei einen zweiten Helfer zuzuteilen. Es gibt aktuelle Orders im Status Akzeptiert oder Zugeteilt. Bist du dir sicher dass du einen zweiten Helfer zuteilen möchtest?',

  disablePendingInvitationTooltip:
    'Es kann keine Standard Order gestartet werden, da es bereits eine Standard Order in dem Order Status Versendet oder Warteschleife gibt.',
  disableScheduledInvitationTooltip:
    'Es kann keine Standard Order gestartet werden, da es bereits eine geplante Standard Order für die Zukunft gibt.',
  invitationTooltip: 'Erstellen Sie eine neue Einladung',
  finish: 'beenden',
  next: 'Weiter',
  later: 'Späterer Zeitpunkt',
  now: 'Ab sofort',
  startDateStepQuestion: 'Wann soll die Order starten?',
  moreInfoNeeded: 'Sind weitere Informationen notwendig?',
  replacementOrderLabel: 'replacementOrderLabel',
  standerOrderLabel: 'standerOrderLabel',
  replacementHelper: 'Helfer, welcher ersetzt werden soll',
  invitationType: 'Einladungstyp',
  invitationTypeLabel: 'Ordertyp',
  noExtraNoteNeeded: 'Keine weiteren Infos notwendig',
  needExtraNote: 'Das sollte beachtet werden',
  extraInfo: 'Weitere Infos',
  endDateStepQuestion: 'Wann soll die Order enden?',
  manualOrAutomatic: 'Welche Art der Zuteilung wird gewünscht?',
  unlimited: 'Unbefristet',
  limited: 'Befristet',
  inputHelperName: 'Helfername eingeben',
  automatic: 'Automatisch',
  list: 'Liste',
  replacement: 'Ersatz',
  standard: 'Standard',
  automaticOrManual: 'Automatisch oder manuell',
  summary: 'Zusammenfassung',
  extraNotes: 'Zusätzliche Infos',
  loopsCount: 'Durchlauf Anzahl',
  sendedType: 'Zuteilungsart',
  stage: 'Phase',
  actions: 'Aktionen',
  manual: 'Manuell',
  dayCreatedAt: 'Tag geschaffen bei',
  journeyTimeInMinutes: 'Fahrzeit in Minuten',
  stageCreatedAt: 'Bühne erstellt bei',
  helperId: 'Helfer ID',
  clientTermination: 'Kundenbeendigung',
  orderFailed: 'Bestellung fehlgeschlagen',
  startNewLoop: 'Neue Schleife starten',
  invitationsOnHoldTitle: 'Warteschleife',
  failedToast: 'Die Einladung änderte sich in erfolgreicher fehlgeschlagener',
  failedModal: 'Sind Sie sicher, dass Sie die Einladung in fehlgeschlagenes ändern möchten?',
  newLoopToast: 'Begann eine neue Schleife für die Einladung erfolgreich',
  newLoopModal: 'Sind Sie sicher, dass Sie eine neue Schleife starten möchten?',
  invitationsOnHoldDescription: 'Die Automatische Zuteilung war nicht erfolgreich.',
  fillPlans: 'Pläne füllen',
  clientPlans: 'Kundenpläne',
  plans: 'Pläne',
  invitation: 'Einladung',
  signatureDeletedSuccessfully: 'Signatur erfolgreich gelöscht',
  monthlyConfirmation: 'Monatliche Bestätigung',
  monthlySignature: 'Monatliche Signatur',
  signatures: 'Unterschriften',
  deleteSignature: 'Signatur löschen',
  deleteSignatureModalContent:
    'Sind Sie sicher, dass Sie diese Signatur löschen möchten?Dies wird dazu führen, dass dieser Besuch unkonventionell wird',
  deleteSignatureModalHeader: 'Besuchen Sie Signature zu besuchen !!',
  deleteMonthlySignatureModalHeader: 'Monatliche Signatur löschen !!',
  deleteMonthlySignatureModalContent:
    'Sind Sie sicher, dass Sie diese Signatur löschen möchten?Durch Löschen dieser Unterschrift werden alle Besuche Signaturen für diesen Kunden gelöscht',
  visitsSignatures: 'Besuche Unterschriften',
  acceptedInvitations: 'Akzeptierte Einladungen',
  acceptedInvitationsDescription: 'Einladungen, die akzeptiert wurden. Ein Termin wurde noch nicht bestätigt.',
  pendingInvitations: 'Ausstehende Einladungen',
  pendingInvitationsDescription: 'Ausstehende und geplante Einladungen, die sich im Zuteilungsprozess befinden.',
  searchByAddress: 'Suche nach Adresse',
  searchByPhone: 'Suche nach Telefonnummer',
  thisInvitationHasCompletedHelperInvitation:
    'Diese Einladung hat eine abgeschlossene Helfereinladung, aber ihre Rechnungen wurden noch nicht berechnet',
  emergencyContactName: 'Notfallkontakt Name der Person',
  emergencyContactPhone: 'Mobilfunknummer des Notfallkontaktes',
  emergencyContactHomeTelephone: 'Festnetznummer des Notfallkontaktes',
  emergencyContactEmail: 'E-Mail Adresse des Notfallkontaktes',
  blockedHelpers: 'Blockierte Helfer',
  editBlocked: 'Bearbeiten blockiert',
  completionDate: 'Abschlussdatum',
  youCanChooseLastMonth: 'Sie können letzten Monat zum Abschlussdatum wählen',
  editInvitationTitle: 'Einladung bearbeiten',
  startNow: 'Jetzt starten',
  averageAcceptTime: 'Durchschnittliche Akzeptanzzeit',
  averageTravelTimeTooltip:
    'Summe der Fahrtzeiten aller akzeptierten Orders in diesem Zeitabschnitt / Anzahl aller akzeptierten Orders in diesem Zeitabschnitt',
  averageTravelTimeDescription: 'Durchschnittliche Fahrtzeit der Helfer zu Ihren Kunden',
  averageTravelTimeTitle: 'Durchschnittliche Fahrtzeit',
  inProgressOverActivePercentageTooltip:
    'Anzahl aller Bestellungen im Übereinstimmungsprozess (Warteschleife + Versendet) jetzt / Anzahl aller Bestellungen (Warteschleife + Versendet + Akzeptiert + Zugeteilt)',
  inProgressOverActivePercentageDescription:
    '(Warteschleife + Versendet)/(Warteschleife + Versendet + Akzeptiert + Zugeteilt)',
  inProgressOverActivePercentageTitle: 'Fluktuationsverhältnis',
  terminationReason: 'Kündigungsgrund',
  terminationRequestedDate: 'Kündigung angefordertes Datum',
  TerminationRequestsDescription: 'Helfer, die bei JUHI kündigen möchten.',
  TerminationRequestsTitle: 'Kündigungsanfragen',
  accept: 'Akzeptieren',
  acceptTerminationRequestToast: 'Helfer -Kündigungsanfrage wird erfolgreich angenommen',
  acceptTerminationRequestModal: 'Sind Sie sicher, dass Sie diese Kündigungsanfrage annehmen möchten?',
  acceptTerminationRequestModalTitle: 'Kündigungsanforderung akzeptieren',
  declineReason: 'Ablehnungsgrund',
  declineOtherReason: 'Anderen Grund ablehnen',
  terminationDeclineReason: 'Bitte geben Sie ein, warum Sie diese Helferanfrage abgelehnt haben',
  firstContactProblemReportDescription: 'Helfer, die ihre Kunden nicht erreichen konnten',
  firstContactProblemReportTitle: 'Erstkontakt Problem',
  solved: 'Solved',
  uploadHelperTerminationMessage:
    'Wenn Sie den Helfer beenden, werden geplante automatische Einladungen für alle seine Kunden ausgelöst',
  invitationStatus: 'Einladungsstatus',
  pending: 'Ausstehend',
  scheduled: 'Geplant',
  sortByCreatedAt: 'Sortieren nach Erstellungsdatum',
  ascending: 'Aufsteigend',
  descending: 'Absteigend',
  automaticInvitationStartDate: 'Start Datum',
  automaticInvitationEndDate: 'End Datum',
  automaticInvitationExtraInfos: 'Extra Infos',
  automaticInvitationMatching: 'Matching',
  automaticInvitationServiceHours: 'Service Stunden',
  automaticInvitationSummary: 'Zusammenfassung',
  automaticInvitationTypeStandard: 'Standard Order',
  automaticInvitationTypeReplacement: 'Ersatz Order',
  automaticInvitationTypeToolTip:
    'Wähle Standardauftrag wenn ein Helfer dauerhaft für diesen Kunden zugeteilt werden soll. Wähle Ersatzauftrag wenn ein bereits zugeteilter Helfer bei diesem Kunden temporär ausfällt und ersetzt werden soll.',
  automaticInvitationTypeStandardToolTip:
    'Dies ist eine Standard Order, du bist dabei dauerhaft einen neuen Helfer diesem Kunden zuzuteilen.',
  automaticInvitationTypeReplacementToolTip:
    'Dies ist eine Ersatz Order, du bist dabei temporär einen verhinderten Helfer zu ersetzen, solange dieser nicht zur Verfügung steht.',
  automaticInvitationStartDateTooltip: `Wähle Jetzt und der Matching Prozess beginnt direkt mit der Zuteilung eines passenden Helfers, nachdem die Order aufgegeben wurde.
  Wenn du einen späteren Zeitpunkt wählst, beginnt der Matching Prozess automatisch mind. 10 Tage vor dem Order Start.
  Erst ab dem gewählten Zeitpunkt können Termine des Helfers in der App eingetragen werden.`,
  automaticInvitationEndDateTooltip: `Ist nur temporär eine Hilfe von JUHI gewünscht wähle Befristet.
    Soll dauerhaft für diesen Kunden eine Hilfe zugeteilt werden, wähle Unbefristet.
    Ist ein Order End Datum gewählt, können nach diesem keine Termine mehr eingetragen werden.`,
  automaticIncitationExtraInfosTooltip: `Sind Order spezifisch weitere Informationen relevant, die nur temporär gültig sind?
    Bspw. Grund der Verhinderung des zugeteilten Helfer, Zuteilung als Zweithelfer, Momentan viel Budget verfügbar, etc.
    Helfer sieht neben dem Auftragstext diese Zusatzinfo in der App.`,
  automaticInvitationNoExtraInfo: `Keine Extra Information notwendig.`,
  automaticInvitationExtraInfo: `Extra Information notwendig.`,
  autoOrManual: 'Welche Zuteilungsart wird gewünscht?',
  autoOrManualTooltip: `Wähle zwischen Automatisch und Manuell.
    Bei der automatischen Zuteilung erhalten alle passenden Helfer die in der Nähe wohnen die Order.
    Bei der manuellen Zuteilung wird ein spezifischer Helfer ausgewählt und direkt zugeteilt. Dies muss natürlich im Vorfeld mit allen involvierten Parteien abgesprochen sein.`,
  inputHelperNameLabel: 'Ersatzhelfer',
  helperViewer: 'Helfersicht',
  age: 'Alter',
  gender: 'Geschlecht',
  jobDescription: 'Auftragstext',
  address: 'Adresse',
  workHours: 'Rhythmus',
  helperServices: 'Aufgaben',
  dateOfIncident: 'Datum des Vorfalls',
  newOrders: 'Neue Orders',
  newOrdersStatus: 'Stati der neuen Orders',
  orderAcceptanceStage: 'Phase der Zuteilung',
  days: 'Tage',
  recent: 'Aktuelle',
  pervious: 'Vorherige',
  clientsWithoutHelpers: 'Neukunden ohne Helfer',
  newClientWithHelpers: 'Neukunden mit Helfer',
  clientsWithHelpers: 'Neukunden mit Helfer',
  orderTypes: 'Order Typ',
  confirmAllTitle: 'Bestätigen Sie alle Monate Bestätigung',
  confirmAllButton: 'Bestätigen Sie alle Monate Bestätigung',
  confirmAllMessage: 'Sind Sie sicher, dass Sie alle monatlichen Bestätigungen erstellen/bestätigen möchten',
  confirmAllSuccess: 'Confirm All Months Confirmation Successfully',
  completeInvitationAndTerminate: 'Complete Invitation And Terminate',
  completeInvitationAndTerminateSuccess: 'Invitations Completed Successfully and Clients/Helpers are Terminated',
  completeInvitationAndTerminateMessage:
    'Are you sure you want to terminate all helpers/clients with scheduled termination date and complete all invitations with end date during and before this month',
  completeInvitationAndTerminateTooltip:
    'This button terminates all helpers/clients with scheduled termination date and completes all invitations with end date during and before this month',
  cantCompleteInvitationAndTerminateTooltip:
    'Sie können nur Einladungen abschließen und Kunden/Helfer in den vergangenen Monaten beenden',
  completeInvitationAndTerminateTitle: 'Complete Invitation And Terminate',
  firstPhone: 'Telefonnummer Erstkontakt',
  otherPhone: 'Weitere Telefonnummer',
  createOrConfirmMonthlyVisit: 'Monatlichen Besuch erstellen/bestätigen',
  createOrConfirmMonthlyVisitQuestion:
    'Sind Sie sicher, dass Sie diese monatliche Bestätigung erstellen oder bestätigen möchten (falls vorhanden)?',
  monthConfirmedSuccessfully: 'Monat erfolgreich bestätigt',
  blockedHelpersUpdatedSuccessfully: 'Blockierte Helfer erfolgreich aktualisiert',
  viewHelperInvitations: 'Helfereinladungen anzeigen',
  startInvitationNow: 'Starten Sie jetzt die Einladung',
  cancelInvitation: 'Einladung abbrechen',
  firstVisit: 'Erstbesuch',
  planned: 'Geplant',
  notPlanned: 'Nicht geplant',
  noSuggestions: 'Keine Vorschläge hinzugefügt werden',
  pendingInvitation: 'Ausstehende Einladung',
  rejectedInvitation: 'Ablehnte Einladung',
  acceptedInvitation: 'Angenommene Einladung',
  applyStageCreatedAt: 'Zustellungsphase',
  removeNotInvited: 'Entfernen Sie nicht eingeladen',
  vacationDeclineReason: 'Urlaubsablehnungsgrund',
  acceptVacationRequestModalTitle: 'Urlaubsanfrage akzeptieren',
  acceptVacationRequestModal: 'Are you sure you want to Accept this vacation request',
  acceptVacationRequestToast: 'Die Helfer -Urlaubsanfrage wird erfolgreich angenommen',
  VacationRequestsTitle: 'Urlaubsanfragen',
  VacationRequestsDescription: 'Helfer, die während eines bestimmten Zeitraums Urlaub machen möchten',
  confirmedAt: 'Bestätigt bei',
  sortByAcceptedAt: 'Sortieren durch akzeptiert bei',
  averageMatchSpeedTooltip:
    'Der Unterschied in den Tagen nach Besuch bestätigte bei - Helfer -Einladung, die auf Einladung akzeptiert wurde, wurde von akzeptiert zu passend',
  averageMatchSpeedDescription:
    'Die Zeit, die der Helfer brauchte, um seinen ersten bestätigten Besuch bei einem neuen Kunden zu haben',
  averageMatchSpeedTitle: 'Durchschnittliche Spielgeschwindigkeitszeit',
  duration: 'Dauer',
  matchSecondHelper: 'Zweiten Helfer zuteilen',
  applyInvitationStatus: 'Einladungsstatus anwenden',
  mapIconTooltip: 'Helfer in der Nähe des Kunden zeigen ',
  pendingDaysCount: 'Ausstehende Tage zählen',
  targetStage: 'Zielstufe',
  loopCreatedAt: 'Schleife erstellt bei',
  goToStage4: 'Gehen Sie auf die Bühne 4',
  goToStage3: 'Gehen Sie auf die Bühne 3',
  goToStage2: 'Gehen Sie auf die Bühne 2',
  removeHelpersWithNoVisits: 'Helfer ohne Besuche entfernen',
  newHelpers: 'Neue Helfer',
  relationshipWithClient: 'In welchem Verhältnis steht der Kontakt zum Kunde',
  phoneNumber: 'Telefonnummer',
  availabilityNote: 'Wann ist der Kunde am besten zu erreichen',
  showInApp: 'Für Erstkontakt verwenden',
  hasVoiceMail: 'AB vorhanden',
  isMainNumber: 'Hauptnummer',
  isClientNumber: 'Die Telefonnummer gehört zum kunden',
  addPhoneNumber: 'Telefonnummer hinzufügen',
  removePhoneNumber: 'Telefonnummer entfernen',
  showAllDone: 'Erledigte Kündigungen einblenden',
  hideAllDone: 'Erledigte Kündigungen ausblenden',
  terminateWithoutDocument: 'ohne Dokument Kündigung',
  unBill: 'Unberechnet',
  approveDiscount: 'Rabatt genehmigen',
  remaining: 'Verbleibend',
  approveDiscountTooltip: 'Der ausgewählte Kunde erhält 2 kostenlose Stunden für seine bevorstehenden Rechnungen.',
  miscellaneousDiscountUsedTooltip:
    'Der ausgewählte Kunde hat bereits seinen 100 Euro -Rabatt verwendet, daher kann MarketingKanal nicht geändert werden.',
  adminApproved: 'Admin genehmigt',
  miscellaneousRewardTakenTooltip:
    'Der ausgewählte Helfer hat bereits ihre Belohnung erhalten, und die Belohnung wurde in einem Vormonat erstellt, daher kann Marketing -Kanal nicht geändert werden.',
  approveReward: 'Belohnung genehmigen',
  approveRewardTooltip: 'Der ausgewählte Helfer erhält seine Belohnung auf der nächsten Rechnung.',
  creationReason: 'Schöpfungsgrund',
  currentStage: 'Momentane Stadium',
  helperTermination: 'Helfer Kündigung',
  helperVacation: 'Helfer ist im Urlaub',
  contractDuration: 'Vertragslaufzeit',
  terminationDate: 'Kündigungsdatum',
  terminationCanceledSuccessfully: 'Kündigung erfolgreich abgesagt',
  cancelTerminationText: 'Sind Sie sicher, dass Sie die Client -Teriantation kündigen möchten?',
  cancelTermination: 'Kündigung stornieren',
  byHelper: 'Von Helfer',
  reason: 'Grund',
  draft: 'Entwurf',
  removeTerminatedClients: 'entfernen Gekündigte Kunden',
  clientWithPendingInvitationsOnly: 'Kunden im Zuteilungsprozess',

  averageHoursPerHelper: 'Stunde pro Helfer',
  averageHoursPerHelperDescription: 'Bestätigte Besuchsstunden/Anzahl aller Helfer pro Monat',
  averageHoursPerActiveHelper: 'Stunde pro aktivem Helfer',
  averageHoursPerActiveHelperDescription: 'Bestätigte Besuchsstunden/Anzahl aktiver Helfer pro Monat',
  averageHoursPerClient: 'Stunde pro Kunde',
  averageHoursPerClientDescription: 'Bestätigte Besuchsstunden/Anzahl aller Kunden pro Monat',
  averageHoursPerActiveClient: 'Stunde pro aktivem Kunden',
  averageHoursPerActiveClientDescription: 'Bestätigte Besuchsstunden/Anzahl aktiver Kunden pro Monat',
  isDraft: 'Ist Entwurf',
  rewarded: 'Belohnt',
  deadline: 'Frist',
  PerformanceMonth: 'Leistungsmonat',
  sendDate: 'Sende Datum',
  reminderNumber: 'Erinnerungsnummer',
  lastReminderDate: 'Letztes Erinnerungsdatum',
  reminder: 'Erinnerung Kunde',
  reminderCount: 'Erinnerungszähler',
  addInvoiceAddress: 'Rechnungsadresse hinzufügen',
  removeInvoiceAddress: 'Rechnungsadresse entfernen',
  invoiceAddressStreet: 'Rechnungsadresse Straße',
  invoiceAddressBuildingNumber: 'Rechnungsadresse Hausnummer',
  invoiceAddressZipCode: 'Rechnungsadresse Postleitzahl',
  invoiceAddressCity: 'Rechnungsadresse Stadt',
  invoiceAddressFirstName: 'Rechnungsadresse Vorname',
  invoiceAddressLastName: 'Rechnungsadresse Nachname',
  temporaryContractDeclineReason: 'Geben Sie den Grund ein, den hochgeladenen Vertrag abzulehnen',
  temporaryContract: 'Temporäre Verträge Tabelle',
  temporaryContractDescription: 'Kunden mit vorübergehenden Verträgen',
  temporaryDocumentApproved: 'Sie haben diesen Vertrag erfolgreich genehmigt',
  temporaryDocumentDeclined: 'Sie haben diesen Vertrag erfolgreich abgelehnt',
  contractStartDate: 'Vertragsbeginn',
  budgetPlan: 'Budgetplan',
  mitVp: 'Mit VP',
  hoursPerMonth: 'Stunden pro Monat',
  deleteVisitModal: 'Sind Sie sicher, dass Sie diesen Besuch löschen möchten?',
  createVisit: 'Besuch erstellen',
  editVisit: 'Besuch bearbeiten',
  invoiceReminder: 'Rechnungserinnerung',
  letterTypes: 'Briefarten',
  jiraStatus: 'Jira Status',
  adminName: 'Admin Name',
  uploadCsvNote: 'Note: Stellen Sie sicher, dass Sie diese Spalten in Ihrem CSV haben(PLZ , Ort)',
  hasPlannedVisits: 'hat nur Besuche geplant',
  visitReport: 'Besuchsberichte',
  consent: 'Erlaubnis erteilt?',
  viaEmail: 'Über E-Mail senden',
  viaSMS: 'Über SMS senden',
  helperVacationWithinTerminationPeriodTooltip: 'Der Urlaub liegt innerhalb der Kündigungsfrist des Helfers',
  customerNotActiveThisMonth: 'Der Kunde war diesen Monat nicht aktiv....',
  lastConfirmedVisitDate: 'Letzter bestätigter Termin',
  confirmTerminatingInPast:
    'Bei einer Kündigung in der Vergangenheit werden die folgenden Besuche/Monatsbestätigungen gelöscht',
  h: 'Std',
  privateBilling: 'Private Abrechnung',
  servicePlans: 'Servicepläne',
  servicePlanDowngradeTitle: 'Herabstufung des Serviceplans',
  servicePlanDowngradeDescription: 'alle Kunden, die den Serviceplan nicht erfüllt haben',
  servicePlanNow: 'Serviceplan jetzt',
  downgradeTo: 'Herabstufen zu',
  termsOfServicePlan: 'Bedingungen des Serviceplans',
  bookedHours: 'Gebuchte Stunden',
  deviationBy: 'Abweichung von',
  downgradePlan: 'Plan herabstufen',
  ignore: 'Ignorieren',
  editClient: 'Client bearbeiten',
  onlyActive: 'Nur aktiv',
  invoiceCopyCount: 'Anzahl der Rechnungskopien',
  InvoiceEmail: 'Rechnungs-E-Mail',
  InactiveHelpersTitle: 'Inaktive Helfer',
  InactiveHelpersDescription: 'Helfer, die in den letzten 15 Tagen nicht aktiv sind',
  editServiceHours: 'Service Stunden bearbeiten',
  servicePlan: 'Serviceplan',
  downgradePlanTooltip: 'Kann nicht herabstufen, da der Client bereits im Basisplan steht',
  unableToChangeReward: 'Sie können die Helferbelohnung nicht ändern, die in einem Vormonat erstellt wurde',
  acceptHelperDocumentConfirmationQuestion: 'Sind Sie sicher, dass Sie dieses Dokument akzeptieren möchten?',
  declineHelperDocumentConfirmationQuestion: 'Sind Sie sicher, dass Sie dieses Dokument ablehnen möchten?',
  acceptedSuccessfully: 'Erfolgreich akzeptiert',
  declinedSuccessfully: 'Erfolgreich abgelehnt',
  uploadedDocumentDeclineReason: 'Geben Sie den Grund ein, den hochgeladenen Dokument abzulehnen',
  cannotPreviewFileNotFound: 'Kann keine Vorschau, Datei nicht gefunden',
  excludeBerlin: 'Berlin ausschließen',
  developers: 'Entwickler',
  ranking: 'Rangfolge',
  bugs: 'Bugs',
  budgetNotReached: 'Budget nicht erreicht',
  serviceHoursNotReached: 'Servicestunden nicht erreicht',
  targetNotReachedReasons: 'Ziel erreichte keine Gründe',
  hoursWithoutCapacity: 'Stunden freie Kapazitäten',
  unusedCapacityServicePlansTitle: 'Nur Servicepläne',
  unusedCapacityServicePlansTooltip:
    'Summe aller Helferkapazitäten - Summe von Serviceplänen, die sich in automatischen Einladungen befinden',
  unusedCapacityServicePlansDescription: 'Die Kapazität der Helfer entspricht keine Servicepläne',
  unusedCapacityBudgetTooltip:
    'Summe aller Helferkapazitäten - Summe von Maxien (Servicepläne, Guthaben), die sich in automatischen Einladungen befinden',
  unusedCapacityBudgetDescription: 'Die Kapazität der Helfer entspricht keinen Serviceplänen + Guthaben',
  unusedCapacityBudgetTitle: 'Servicepläne mit Guthaben',
  prioritized: 'Priorisiert',
  showVisitReport: 'Besuchsbericht anzeigen',
  additionalActivities: 'Zusätzliche Aktivitäten',
  generalInformation: 'Allgemeine Informationen',
  twoMonthInRow: 'Zwei Monate in der Reihe',
  automaticInvitation: 'Automatische Einladung',
  paused: 'Pausiert',
  attributes: 'Attributes',
  firstPlannedVisit: 'Erster geplanter Besuch',
  unclearClientsDescription: 'Unklare Kunden im Vormonat',
  unclearClientsTitle: 'Unklare Kunden',
  capacity: 'Helferkapazität',
  noCapacity: 'Hat keine Kapazität',
  totalHours: 'Gesamtstunden',
  serviceTypeQuestion: 'Wo lag der heutige Schwerpunkt der Dienstleistung?',
  tasksCompletedWithinTimeQuestion:
    'Konnten die Aufgaben / Wünsche des Kunden, innerhalb der vorgesehenen Zeit erledigt werden?',
  additionalActivitiesQuestion: 'Wurden neben den üblichen Tätigkeiten, weitere ausgeführt? Wenn ja, welche?',
  generalInformationQuestion: 'Wie ist der heutige Termin verlaufen? Was habt ihr gemacht?',
  withBudget: 'Mit Guthaben',
  hrs: 'Std.',
  emptyContractWarning: 'WARNUNG: Fehlende Felder im Kundenformular fehlen im Vertrag',
  clientPreferences: 'Kundenpräferenzen',
  otherReasons: 'Andere Gründe',
  budgetHours: 'Guthabenstunden',
  visitsHours: 'Besuche Stunden',
  notReachingTargetReasons: 'Not Reaching Target Reasons',
  remainingBudget: 'Übriges Guthaben',
  exceedingHours: 'Überschreitende Stunden',
  exceedingHoursTitle: 'Überstunden Stunden',
  exceedingHoursDescription:
    'Kunden, die ihr Budget überschreiten, wenn sie gleiche Stunden wie im letzten Monat genutzt werden',
  exceedingHoursSolvedToast: 'Der Kunde übersteigt die Stunden erfolgreich gelöst den Status',
  visitHoursLastMonth: 'Besuchsstunden im letzten Monat',
  adminReasonQuestion: 'Aus welchem ​​Grund wurde die Einladung erstellt?',
  adminReasonLabel: 'Admin Reason',
  adminNotes: 'Admin-Notizen',
  contract110: 'Vertrag 110',
  contract110HelperTask: 'Helfer mit Vertrag vom Typ 110 und Arbeitstagen mehr als 60',
  relationshipWithHelper: 'Beziehung zu Helfer',
  isHelperNumber: 'Helfer eigene Nummer',
  useForServiceRequests: 'Verwendung für Serviceanfragen',
  remainingUnsolved: 'Ungelöst bleiben',
  nursingAdvisor: 'Pflegeberater',
  nursingAdvisors: 'Pflegeberater',
  adminReasonExplain: 'Zusätzliche Erklärung (optional)',
  otherReason: 'Anderer Grund',
  extraExplain: 'Zusätzliche Erklärungen',
  useServiceHoursInsteadOfBudget: 'Verwenden Sie Servicezeiten anstelle des Budgets',
  clientRelativeName: 'Doppelauftrag',
  clientRelative: 'Doppelauftrag',
  hourlyOverrunSolved: 'Stundenüberschreitung gelöst',
  hourlyOverrunTitle: 'Stundenüberschreitung',
  hourlyOverrunDescription: 'Helfer, die diesen Monat mehr als 35 Stunden arbeiteten',
  confirmedHours: 'Bestätigte Stunden',
};
