import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import GenericInput from 'components/GenericInput/GenericInput';
import styles from './formFieldStyle';
const useStyles = makeStyles(styles);
export default function FormField({
  type,
  name,
  label,
  form,
  options,
  getShowCondition,
  dependencyList,
  rules,
  tooltip,
  tooltipColor,
  isCollapsed = true,
  dropFileProps = {},
  extraText,
}) {
  const classes = useStyles();

  const { control, setValue, errors, watch } = form;

  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    if (getShowCondition && getShowCondition()) {
      setIsShown(true);
    } else if (getShowCondition && !getShowCondition()) {
      setIsShown(false);
      setValue(name, null);
    }
  }, [...dependencyList]);

  const handleChange = prop => event => setValue(prop, event.target.value);
  const genericInputContainerClasses = classNames({
    [classes.width100]: !(tooltip && type != 'switch'),
    [classes.width85]: tooltip && type != 'switch',
  });
  const tooltipIconClasses = classNames({
    [classes.icon]: type != 'switch',
    [classes.info]: tooltipColor === 'info' || !tooltipColor,
    [classes.danger]: tooltipColor === 'danger',
    [classes.warning]: tooltipColor === 'warning',
  });

  const tooltipContent = tooltip && type != 'switch' && (
    <div className={classes.tooltip}>
      <Tooltip title={tooltip} arrow>
        <InfoOutlined className={tooltipIconClasses} />
      </Tooltip>
    </div>
  );
  const field = (
    <div className={classes.container}>
      {extraText && <div className={classes.extraText}>{extraText}</div>}

      <div className={genericInputContainerClasses}>
        <Controller
          render={({ value }) => {
            return (
              <GenericInput
                tooltip={tooltip}
                tooltipIconClasses={tooltipIconClasses}
                type={type}
                name={name}
                label={label}
                value={value}
                error={errors[name]?.message}
                onChange={
                  type === 'text' ||
                  type === 'number' ||
                  type === 'date' ||
                  type === 'datetime-local' ||
                  type === 'select' ||
                  type === 'time' ||
                  type === 'month'
                    ? handleChange(name)
                    : type === 'autocomplete'
                    ? (_, newValue) => {
                        setValue(name, newValue);
                      }
                    : type === 'switch'
                    ? () => setValue(name, !value)
                    : type === 'rich-text'
                    ? editorState => {
                        setValue(
                          name?.split('@')?.length > 1
                            ? `${name?.split('@')[0]}Text@${name?.split('@')[1]}`
                            : `${name}Text`,
                          editorState.getCurrentContent().getPlainText(),
                        );
                      }
                    : type === 'image' || type === 'dropzone'
                    ? file => setValue(name, file)
                    : null
                }
                options={{
                  ...options,
                  ...(type === 'rich-text'
                    ? {
                        setRteValue: value => setValue(name, value),
                        rteDir: options?.getDirection
                          ? options?.getDirection(watch)
                          : options?.dir
                          ? options?.dir
                          : 'ltr',
                      }
                    : {}),
                }}
                dropFileProps={dropFileProps}
              />
            );
          }}
          name={name}
          control={control}
          rules={rules}
        />
      </div>

      {tooltipContent}
    </div>
  );
  return getShowCondition === undefined ? (
    field
  ) : (
    <Fade unmountOnExit mountOnEnter collapse={isCollapsed} when={isShown} duration={700}>
      <div
        style={{
          minHeight: 55 + (options?.multiline ? (options?.rows ? options.rows * 17 : 5 * 17) : 0),
        }}
      >
        {field}
      </div>
    </Fade>
  );
}

FormField.defaultProps = {
  dependencyList: [],
};

FormField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  form: PropTypes.object.isRequired,
  options: PropTypes.object,
  getShowCondition: PropTypes.func,
  dependencyList: PropTypes.array,
  rules: PropTypes.object,
  tooltip: PropTypes.string,
  tooltipColor: PropTypes.string, // hex color
  isCollapsed: PropTypes.bool,
  dropFileProps: PropTypes.object,
  extraText: PropTypes.string,
};
/*
- Component props are:

  1. name: field name in form
  2. type: field type
  3. label: displayed field label and placeholder
  4. options: options object of the corresponding type
  5. validation: validation schema for the field
  6. getSubmitValue: function that converts field values before submitting the form
  7. getShowCondition: function that returns a boolean to show the field when true
  8. dependencyList: dependency list that updates visibility status


- type values are:

1. text:
  uses CustomInput component with type text
  options => {
    multiline: bool
    rows: number
  }

2. number:
  uses CustomInput component with type number

3. date:
  uses CustomInput component with type date

4. datetime-local:
  uses CustomInput component with type datetime-local

5. select:
  uses SelectInput component
  options => {
    data: select options array
    getData: function that populates data prop in SelectInput ,,,, (either send data prop or getData)
    rawDataConvertor: map to convert fetched values from getData to [{name: "example", value: 0}] (optional)
  }

6. autocomplete:
  uses AutocompleteInput component
  options => {
    data: autocomplete options array
    getData: function that populates options prop in AutocompleteInput ,,,, (either send data prop or getData)
    getOptionLabel: same as AutocompleteInput *required
    ... whatever the AutocompleteInput takes
  }

7. switch:
  uses CustomSwitch component

8. rich-text:
  uses RichTextInput component
  options => {
    dir: ltr/rtl (ltr by default)
    getDirection: function to determine direction. Takes watch as argument (should return ltr or rtl)
  }

9. image:
  uses ImageUpload component

10. dropzone:
  uses DropFiles component

*/
